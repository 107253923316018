body {
    font-family: Arial, Tahoma, Verdana, sans-serif;
    color: #858585;
}

h1, hr {
    color: #6c826c;
    font-size: 2.5em;
}

h1, h2 {
    font-weight: bold;
}

footer {
    margin-bottom: 20px;
}

@media (max-width: 768px) {
    h1 {
        font-size: 2em;
        text-align: center;
    }
    h1 > .sub {
        font-size: 0.8em;
    }
    h2 {
        font-size: 1.5em;
    }
}

#map {
    width: 100%;
    height: 400px;
}

.flower {
    height: 325px;
}

.flower-label {

}

.bold {
    font-weight: bold;
}

#navbar-brand-green {
    color: #6c826c;
}
